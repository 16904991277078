















































import DraftActions from '@/builder/mixins/DraftActions'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import mixins from 'vue-typed-mixins'
import { mapActions, mapGetters } from 'vuex'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import SectionConfigurationEmployee from '@/calendesk/models/SectionConfigurationEmployee'
import SelectableEmployee from '@/calendesk/models/SelectableEmployee'
export default mixins(DraftActions).extend({
  name: 'EmployeesSelect',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      mode: DraftActionsType.SECTION,
      loading: false,
      items: Array<SelectableEmployee>()
    }
  },
  computed: {
    ...mapGetters({
      selectedSection: 'builder/getSelectedSection',
      employees: 'employee/getEmployees'
    })
  },
  watch: {
    data () {
      this.reloadItems()
    }
  },
  mounted () {
    if (!this.employees || !this.employees.length) {
      this.loading = true
      this.fetchEmployees(() => {
        this.reloadItems()
      }).finally(() => { this.loading = false })
    } else {
      this.reloadItems()
    }
  },
  methods: {
    ...mapActions({
      fetchEmployees: 'employee/fetch'
    }),
    reloadEmployees () {
      this.loading = true
      this.fetchEmployees().finally(() => {
        this.loading = false
      })
    },
    updateConfiguration () {
      const result = Array<SectionConfigurationEmployee>()
      this.items.forEach((item: SelectableEmployee) => {
        if (item.selected) {
          result.push({ id: item.employee.id })
        }
      })
      this.updateConfigurationProperty(this.data.key, result)
    },

    reloadItems (): void {
      const selectedEmployeeIds = this.selectedSection.configuration[this.data.key].map((item: SectionConfigurationEmployee) => item.id)
      this.items = []

      this.employees.forEach((employee: Employee) => {
        const isSelected = selectedEmployeeIds.includes(employee.id)
        this.items.push(new SelectableEmployee(isSelected, employee))
      })
    }
  }
})
