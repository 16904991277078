import Employee from '@/calendesk/models/DTO/Response/Employee'

export default class SelectableEmployee {
  selected: boolean;
  employee: Employee

  constructor (selected: boolean, employee: Employee) {
    this.selected = selected
    this.employee = employee
  }
}
